import UploadInput from "@cospex/client/converter/components/UploadInput";
import { OPERATION } from "@cospex/client/converter/constants";
import { getOperationFromQuery } from "@cospex/client/converter/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";

export default function HomepageUploaderInput({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { t } = useTranslation();
  const { compressionFormat, convertFrom, convertTo, specificConversion } =
    getOperationFromQuery();
  return (
    <UploadInput
      limit={1}
      limitError={t("exceeded-homepage-upload-limit")}
      type={
        compressionFormat !== null
          ? OPERATION.COMPRESSION
          : OPERATION.CONVERSION
      }
      compressionStyle={
        compressionFormat !== null ? { target: compressionFormat } : undefined
      }
      conversionStyle={
        specificConversion ? { from: convertFrom, to: convertTo } : undefined
      }
    >
      {children}
    </UploadInput>
  );
}
