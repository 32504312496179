import { Container, Stack, SxProps } from "@mui/material";

export default function ContainerElement({
  backgroundColor = "transparent",
  children,
  sx = {},
}: {
  backgroundColor?: string;
  children: React.ReactNode;
  sx?: SxProps;
}) {
  return (
    <Stack
      sx={{
        ...sx,
        backgroundColor,
      }}
    >
      <Container>{children}</Container>
    </Stack>
  );
}
