import {
  getExtensionColor,
  getOperationFromQuery,
} from "@cospex/client/converter/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Typography } from "@mui/material";

export default function SectionHeroTitle() {
  const { t } = useTranslation();

  const {
    convertFrom,
    convertTo,
    compressionFormat,
    convertFromUppercase,
    convertToUppercase,
    specificConversion,
  } = getOperationFromQuery();

  const extensionColor = (extension: string) =>
    getExtensionColor(extension) || "primary.main";

  const fs = 32;
  const renderCompressionTitle = () => (
    <Typography sx={{ fontSize: fs, fontWeight: "bold" }}>
      {compressionFormat ? (
        <>
          {t("homepage-title-compress-a")}{" "}
          <Box
            component="span"
            sx={{ color: extensionColor(compressionFormat) }}
          >
            {compressionFormat.toUpperCase()}
          </Box>{" "}
          {t("homepage-title-compress-b")}
        </>
      ) : (
        <>
          {t("homepage-title-compress-default-a")}{" "}
          <Box component="span" sx={{ color: "primary.main" }}>
            {t("homepage-title-compress-default-b")}
          </Box>
        </>
      )}
    </Typography>
  );

  const renderConversionTitle = () => (
    <Typography
      sx={{
        fontSize: {
          xs: 20,
          md: fs,
        },
        fontWeight: "bold",
      }}
    >
      {(() => {
        switch (true) {
          case specificConversion:
            return (
              <>
                {t("homepage-title-custom-a")}{" "}
                <Box
                  component="span"
                  sx={{ color: extensionColor(convertFrom) }}
                >
                  {convertFromUppercase}
                </Box>{" "}
                {t("homepage-title-custom-b")}{" "}
                <Box component="span" sx={{ color: extensionColor(convertTo) }}>
                  {convertToUppercase}
                </Box>
              </>
            );
          case convertFrom !== undefined:
            return (
              <>
                {t("homepage-title-custom-a")}{" "}
                <Box
                  component="span"
                  sx={{ color: extensionColor(convertFrom) }}
                >
                  {convertFromUppercase}
                </Box>{" "}
              </>
            );
          default:
            return (
              <>
                {t("homepage-title-a")} {t("homepage-title-b")}{" "}
                <Box component="span" sx={{ color: "primary.main" }}>
                  {t("homepage-title-c")}
                </Box>
              </>
            );
        }
      })()}
    </Typography>
  );

  return compressionFormat !== null
    ? renderCompressionTitle()
    : renderConversionTitle();
}
