import { API_PATH_REQUEST_NEW_PASSWORD } from "@cospex/client/constants";
import TextInput from "@cospex/client/forms/TextInput";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Container, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

type ResetPasswordData = {
  email: string;
};

const validationSchema = z.object({
  email: z.string().email("reset-pass-email-required").min(1),
});

const ResetPassword = () => {
  const { t } = useTranslation();
  const emailRef = useRef<HTMLInputElement>(null);
  const { control, handleSubmit, formState } = useForm<ResetPasswordData>({
    resolver: zodResolver(validationSchema),
  });
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const [error, setError] = useState<null | string>();

  const mutation = useMutation({
    mutationFn: ({ email }: ResetPasswordData) => {
      return axios.post(
        API_PATH_REQUEST_NEW_PASSWORD,
        JSON.stringify({ email }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: () => {
      setResetSuccessful(true);
    },
    onError(res: any) {
      const error = res.response?.data?.message || res.message;
      setError(error);
    },
  });

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  return (
    <Container
      sx={{
        minHeight: "66vh",
        py: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        onSubmit={handleSubmit((data: ResetPasswordData) =>
          mutation.mutate(data)
        )}
      >
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            gap: 2,
          }}
        >
          <Typography variant="h2">{t("reset-pass-title")}</Typography>
          <TextInput
            fullWidth
            control={control}
            name="email"
            formState={formState}
            label={t("reset-pass-email-label")}
          />
          <LoadingButton
            loading={mutation.isLoading}
            type="submit"
            disableElevation
            variant="contained"
          >
            <span>{t("reset-pass-title")}</span>
          </LoadingButton>
          {resetSuccessful && (
            <Alert severity="success">{t("reset-pass-sent")}</Alert>
          )}
          {error && <Alert severity="error">{error}</Alert>}
        </Stack>
      </Box>
    </Container>
  );
};

export default ResetPassword;
