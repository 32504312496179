import Container from "@cospex/client/converter/components/Container";
import {
  getExtensionColor,
  getOperationFromQuery,
} from "@cospex/client/converter/helpers";
import ArrowImage from "@cospex/client/converter/img/arrow.svg";
import HomeStepsImage1 from "@cospex/client/converter/img/home_steps_image_1.svg";
import HomeStepsImage2 from "@cospex/client/converter/img/home_steps_image_2.svg";
import HomeStepsImage3 from "@cospex/client/converter/img/home_steps_image_3.svg";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Card, Stack, Typography } from "@mui/material";
import { Trans } from "react-i18next";

export default function HowItWorksSection() {
  const { t } = useTranslation();
  const {
    convertFrom,
    convertTo,
    convertFromUppercase,
    convertToUppercase,
    specificConversion,
  } = getOperationFromQuery();

  return (
    <Container
      sx={{
        pb: {
          lg: 14,
          md: 12,
          xs: 0,
        },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
        }}
      >
        {specificConversion ? (
          <Trans
            i18nKey="how-to-convert"
            defaults="How to convert <from>{{from}}</from> to <to>{{to}}</to>?"
            values={{ from: convertFromUppercase, to: convertToUppercase }}
            components={{
              from: <span style={{ color: getExtensionColor(convertFrom) }} />,
              to: (
                <span
                  style={{
                    color: getExtensionColor(convertTo),
                  }}
                />
              ),
            }}
          />
        ) : (
          t("how-it-works")
        )}
      </Typography>
      <Stack
        display="flex"
        sx={{
          flexDirection: {
            md: "row",
            xs: "column",
          },
          py: 8,
        }}
        gap={2}
      >
        <Card
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 4,
            boxShadow: 4,
            border: 1,
            borderColor: "primary.main",
            borderRadius: 2,
            transform: {
              md: "translateY(50px)",
              xs: "translateY(0px)",
            },
          }}
        >
          <Box
            component="img"
            style={{
              width: 70,
              height: 70,
            }}
            src={HomeStepsImage1}
          />
          <Typography variant="h5" color="primary.main">
            {specificConversion
              ? t("homepage-steps-1-title-specific", {
                  file: convertFromUppercase,
                })
              : t("homepage-steps-1-title")}
          </Typography>
          <Typography variant="body1">
            {specificConversion
              ? t("homepage-steps-1-description-specific", {
                  from: convertFromUppercase,
                  to: convertToUppercase,
                })
              : t("homepage-steps-1-description")}
          </Typography>
        </Card>
        <Box
          sx={{
            pt: 6,
            display: {
              md: "block",
              xs: "none",
            },
          }}
        >
          <Box
            component="img"
            src={ArrowImage}
            sx={{
              width: {
                lg: 80,
                md: 40,
              },
            }}
          />
        </Box>
        <Card
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            boxShadow: 4,
            border: 1,
            borderColor: "primary.main",
            borderRadius: 2,
          }}
        >
          <Box
            component="img"
            style={{
              width: 70,
              height: 70,
            }}
            src={HomeStepsImage2}
          />
          <Typography variant="h5" color="primary.main">
            {t("homepage-steps-2-title")}
          </Typography>
          <Typography variant="body1">
            {specificConversion
              ? t("homepage-steps-2-description-specific", {
                  from: convertFromUppercase,
                  to: convertToUppercase,
                })
              : t("homepage-steps-2-description")}
          </Typography>
        </Card>
        <Box
          sx={{
            pb: 6,
            display: {
              md: "flex",
              xs: "none",
            },
            alignItems: "flex-end",
          }}
        >
          <Box
            component="img"
            src={ArrowImage}
            sx={{
              width: {
                lg: 80,
                md: 40,
              },
              transform: "scaleY(-1)",
            }}
          />
        </Box>
        <Card
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            boxShadow: 4,
            border: 1,
            borderColor: "primary.main",
            borderRadius: 2,
            transform: {
              md: "translateY(100px)",
              xs: "translateY(0px)",
            },
          }}
        >
          <Box
            component="img"
            style={{
              width: 70,
              height: 70,
            }}
            src={HomeStepsImage3}
          />
          <Typography variant="h5" color="primary.main">
            {specificConversion
              ? t("homepage-steps-3-title-specific", {
                  file: convertToUppercase,
                })
              : t("homepage-steps-3-title")}
          </Typography>
          <Typography variant="body1">
            {specificConversion
              ? t("homepage-steps-3-description-specific", {
                  from: convertFromUppercase,
                  to: convertToUppercase,
                })
              : t("homepage-steps-3-description")}
          </Typography>
        </Card>
      </Stack>
    </Container>
  );
}
