import { API_PATH_ANALYTICS } from "@cospex/client/constants";
import { setItemWithExpiry } from "@cospex/client/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Alert, Container, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const FollowUp = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [dots, setDots] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get("email");
    const utmSource = searchParams.get("utm_source");
    const utmMedium = searchParams.get("utm_medium");
    if (!email) {
      setError("followup-malformed-url");
      return;
    }
    if (!utmSource || !utmMedium) {
      throw new Error("No utm_source or utm_medium provided on followup");
    }
    axios
      .post(API_PATH_ANALYTICS, {
        email,
        utm_source: utmSource,
        utm_medium: utmMedium,
      })
      .then(() => {
        setItemWithExpiry("onboardingEmail", email);
        navigate("/payment");
      })
      .catch(() => {
        setError("followup-tracking-error");
      });
  }, []);

  useEffect(() => {
    const i = setInterval(() => {
      setDots((dots) => {
        if (dots.length === 3) {
          return "";
        }
        return dots + ".";
      });
    }, 750);
    return () => clearInterval(i);
  }, []);

  return (
    <Container>
      {error ? (
        <Alert severity="error">{t(error)}</Alert>
      ) : (
        <Typography variant="body1" mb={3}>
          {t("followup-body")}
          {dots}
        </Typography>
      )}
    </Container>
  );
};

export default FollowUp;
