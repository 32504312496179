import SectionCTA from "@cospex/client/converter/components/SectionCTA";
import SectionFAQ from "@cospex/client/converter/components/SectionFAQ";
import SectionHero from "@cospex/client/converter/components/SectionHero";
import SectionHowItWorks from "@cospex/client/converter/components/SectionHowItWorks";
import SectionPricing from "@cospex/client/converter/components/SectionPricing";
import SectionReviews from "@cospex/client/converter/components/SectionReviews";
import SectionSecurity from "@cospex/client/converter/components/SectionSecurity";
import SectionSteps from "@cospex/client/converter/components/SectionSteps";
import SectionWhyChoose from "@cospex/client/converter/components/SectionWhyChoose";
import UploadsCounter from "@cospex/client/converter/components/UploadsCounter";

export default function Home() {
  return (
    <>
      <SectionHero />
      <UploadsCounter />
      <SectionReviews />
      <SectionHowItWorks />
      <SectionSteps />
      <SectionWhyChoose />
      <SectionCTA />
      <SectionFAQ />
      <SectionPricing />
      <SectionSecurity />
    </>
  );
}
