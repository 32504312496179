import { ABTEST_VERSION } from "@cospex/client/abtest";
import SectionHeroInnerDefault from "@cospex/client/converter/components/SectionHeroInnerDefault";
import SectionHeroInnerLarge from "@cospex/client/converter/components/SectionHeroInnerLarge";
import { ABTESTS } from "@cospex/client/converter/constants";
import { Container } from "@mui/material";

export default function HeroSection() {
  return (
    <>
      <Container
        sx={{
          pt: {
            xs: 4,
          },
          mb: {
            xs: 2,
            sm: 4,
            md: 8,
          },
        }}
      >
        {ABTEST_VERSION === ABTESTS.LARGE_UPLOADER ? (
          <SectionHeroInnerLarge />
        ) : (
          <SectionHeroInnerDefault />
        )}
      </Container>
    </>
  );
}
