import ImageConversionPreview from "@cospex/client/converter/components/ImageConversionPreview";
import PDFConversionPreview from "@cospex/client/converter/components/PDFConversionPreview";
import UniversalConversionPreview from "@cospex/client/converter/components/UniversalConversionPreview";
import useUploads from "@cospex/client/converter/hooks/useUploads";

export default function ConverterPaymentPreview() {
  const { uploads } = useUploads();
  const upload = uploads[0];

  if (!upload) return null;
  if (upload.convertTo === "pdf" || upload.type === "pdf") {
    return <PDFConversionPreview upload={upload} />;
  } else if (upload.baseType === "image") {
    return <ImageConversionPreview upload={upload} />;
  }
  return <UniversalConversionPreview upload={upload} />;
}
