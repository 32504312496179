import HomepageUploaderContainer from "@cospex/client/converter/components/HomepageUploaderContainer";
import HomepageUploaderInput from "@cospex/client/converter/components/HomepageUploaderInput";
import UploaderLoadingOverlay from "@cospex/client/converter/components/UploaderLoadingOverlay";
import UploaderSubmitButton from "@cospex/client/converter/components/UploaderSubmitButton";
import UploadsList from "@cospex/client/converter/components/UploadsList";
import { FILE_SIZE_LIMIT } from "@cospex/client/converter/constants";
import { getOperationFromQuery } from "@cospex/client/converter/helpers";
import useHomepageUploaderState from "@cospex/client/converter/hooks/useHomepageUploaderState";
import useUploads from "@cospex/client/converter/hooks/useUploads";
import TextInput from "@cospex/client/forms/TextInput";
import { formatBytes } from "@cospex/client/helpers";
import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Alert, Box, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function HomepageUploaderLarge() {
  const { t } = useTranslation();
  const { uploads } = useUploads();
  const { session } = useAuth();

  const { control, onHandleSubmit, formState, showProgress, error, hasUpload } =
    useHomepageUploaderState();

  const navigate = useNavigate();

  const {
    compressionFormat,
    convertFromUppercase,
    convertToUppercase,
    specificConversion,
  } = getOperationFromQuery();

  useEffect(() => {
    if (hasUpload && !session) {
      const emailInput = document.querySelector(
        "[name=email]"
      ) as HTMLInputElement;
      emailInput?.focus();
    }
  }, [hasUpload, session]);

  return (
    <>
      <HomepageUploaderContainer
        sx={{
          height: {
            xs: 320,
            md: 400,
            lg: 480,
          },
        }}
      >
        <HomepageUploaderInput>
          <UploaderSubmitButton
            sx={{
              maxWidth: 220,
              fontSize: 12,
              mb: 2,
            }}
            hasUpload={false}
            compressionFormat={compressionFormat}
          />
        </HomepageUploaderInput>
      </HomepageUploaderContainer>

      {error && (
        <Alert sx={{ mt: 2 }} severity="error">
          {t(error)}
        </Alert>
      )}

      {uploads.length > 0 && (
        <Stack
          direction="column"
          gap={2}
          sx={{
            width: {
              xs: "100%",
              md: "80%",
            },
          }}
        >
          <UploadsList
            sx={{
              border: "1px solid",
              borderColor: "grey.200",
              borderRadius: 2,
              px: 2,
              py: 1,
            }}
          />
          <Stack
            component="form"
            direction="row"
            onSubmit={onHandleSubmit}
            justifyContent="center"
            gap={2}
          >
            {!session && (
              <Box sx={{ flex: 0.7 }}>
                <TextInput
                  control={control}
                  fullWidth
                  name="email"
                  label="Email"
                  formState={formState}
                />
              </Box>
            )}
            <Box sx={{ flex: 0.3, mt: "-3px" }}>
              <UploaderSubmitButton
                hasUpload={hasUpload}
                compressionFormat={compressionFormat}
              />
            </Box>
          </Stack>
          <UploaderLoadingOverlay
            showProgress={showProgress}
            onComplete={() => {
              navigate("/payment");
            }}
          />
        </Stack>
      )}
      {specificConversion && (
        <Typography textAlign="center">
          {t("average-conversion-time-specific", {
            from: convertFromUppercase,
            to: convertToUppercase,
          })}
        </Typography>
      )}
    </>
  );
}
