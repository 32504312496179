import useTranslation from "@cospex/client/hooks/useTranslation";
import { CheckCircleOutline } from "@mui/icons-material";
import { Box } from "@mui/material";

const Step = ({
  stepNumber,
  label,
  icon,
  opacity = 1,
}: {
  stepNumber: number;
  label: string;
  icon?: boolean;
  opacity?: number;
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      margin: {
        sm: 0,
        xs: stepNumber === 2 ? "0 -1.8rem 0 -1.8rem" : 0,
      },
      opacity,
    }}
  >
    <Box
      sx={{
        mb: 1.6,
      }}
    >
      {icon ? (
        <Box
          sx={{
            width: 28,
            height: 28,
            overflow: "hidden",
          }}
        >
          <CheckCircleOutline fontSize="medium" sx={{ color: "#00B448" }} />
        </Box>
      ) : (
        <Box
          sx={{
            width: 28,
            height: 28,
            border: "1px solid grey",
            borderRadius: 28,
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {stepNumber}
        </Box>
      )}
    </Box>
    <Box
      sx={{
        display: {
          md: "block",
          sm: "block",
          xs: stepNumber === 2 ? "block" : "none",
        },
        fontSize: 12.8,
        fontWeight: stepNumber === 2 ? 600 : "none",
      }}
    >
      {label}
    </Box>
  </Box>
);

const Line = () => (
  <Box
    sx={{
      width: "12%",
      pt: 1.6,
    }}
  >
    <Box
      sx={{
        height: "0.8px",
        background: "grey",
      }}
    />
  </Box>
);

export default function Stepper() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        fontSize: "0.88rem",
        justifyContent: "center",
        gap: {
          sm: 0,
          xs: 0.8,
        },
        mb: 2.4,
        pb: 2.4,
        borderBottom: "1px solid whitesmoke",
      }}
    >
      <Step stepNumber={1} label={t("payment-step-1")} icon />
      <Line />
      <Step stepNumber={2} label={t("payment-step-2")} />
      <Line />
      <Step stepNumber={3} label={t("payment-step-3")} opacity={0.3} />
    </Box>
  );
}
