import {
  getExtensionColor,
  getOperationFromQuery,
} from "@cospex/client/converter/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { Box, Card, Typography } from "@mui/material";
import { Trans } from "react-i18next";

const WhyChooseUs = () => {
  const { t } = useTranslation();
  const {
    convertFrom,
    convertTo,
    specificConversion,
    convertFromUppercase,
    convertToUppercase,
  } = getOperationFromQuery();
  return (
    <>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        {specificConversion ? (
          <Trans
            i18nKey="homepage-features-title-specific"
            defaults="Why choose our <from>{{from}}</from> to <to>{{to}}</to> converter"
            values={{ from: convertFromUppercase, to: convertToUppercase }}
            components={{
              from: (
                <span
                  style={{
                    color: getExtensionColor(convertFrom),
                  }}
                />
              ),
              to: (
                <span
                  style={{
                    color: getExtensionColor(convertTo),
                  }}
                />
              ),
            }}
          />
        ) : (
          t("homepage-features-title")
        )}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          pt: 6,
          "& .MuiCard-root": {
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          },
          "& .MuiSvgIcon-root": {
            fontSize: "2rem",
            color: "primary.main",
          },
        }}
      >
        <Card elevation={6}>
          <VerifiedUserOutlinedIcon />
          <Typography variant="h5">{t("homepage-features-a")}</Typography>
          <Typography variant="body2">
            {specificConversion
              ? t("homepage-features-a-description-specific", {
                  from: convertFromUppercase,
                  to: convertToUppercase,
                })
              : t("homepage-features-a-description")}
          </Typography>
        </Card>
        <Card elevation={6}>
          <CloudUploadOutlinedIcon />
          <Typography variant="h5">{t("homepage-features-b")}</Typography>
          <Typography variant="body2">
            {specificConversion
              ? t("homepage-features-b-description-specific", {
                  from: convertFromUppercase,
                  to: convertToUppercase,
                })
              : t("homepage-features-b-description")}
          </Typography>
        </Card>
        <Card elevation={6}>
          <FolderOpenOutlinedIcon />
          <Typography variant="h5">{t("homepage-features-c")}</Typography>
          <Typography variant="body2">
            {specificConversion
              ? t("homepage-features-c-description-specific", {
                  from: convertFromUppercase,
                  to: convertToUppercase,
                })
              : t("homepage-features-c-description")}
          </Typography>
        </Card>
      </Box>
    </>
  );
};

export default WhyChooseUs;
