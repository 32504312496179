import Container from "@cospex/client/converter/components/Container";
import { getOperationFromQuery } from "@cospex/client/converter/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

export default function FAQSection() {
  const { t } = useTranslation();
  const { convertFromUppercase, convertToUppercase, specificConversion } =
    getOperationFromQuery();

  return (
    <Container>
      <Box>
        <Stack alignItems="center">
          <Typography variant="h2" sx={{ padding: "20px" }}>
            {t("homepage-faq-title")}
          </Typography>
        </Stack>
        <Box
          sx={{
            "> .MuiAccordion-root": {
              boxShadow: "none",
              margin: "16px 0",
            },
            "> .MuiAccordion-root:before": {
              display: "none",
            },
            ".MuiTypography-body2": {
              fontSize: "1.2rem",
            },
            borderRadius: "8px",
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {specificConversion
                  ? t("homepage-faq-accordion-a-title-specific", {
                      from: convertFromUppercase,
                      to: convertToUppercase,
                    })
                  : t("homepage-faq-accordion-a-title")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                {specificConversion
                  ? t("homepage-faq-accordion-a-body-specific", {
                      from: convertFromUppercase,
                      to: convertToUppercase,
                    })
                  : t("homepage-faq-accordion-a-body")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1b-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {t("homepage-faq-accordion-b-title")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                {t("homepage-faq-accordion-b-body")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {specificConversion
                  ? t("homepage-faq-accordion-c-title-specific", {
                      from: convertFromUppercase,
                      to: convertToUppercase,
                    })
                  : t("homepage-faq-accordion-c-title")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                {specificConversion
                  ? t("homepage-faq-accordion-c-body-specific", {
                      from: convertFromUppercase,
                      to: convertToUppercase,
                    })
                  : t("homepage-faq-accordion-c-body")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {specificConversion
                  ? t("homepage-faq-accordion-d-title-specific", {
                      from: convertFromUppercase,
                      to: convertToUppercase,
                    })
                  : t("homepage-faq-accordion-d-title")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                {specificConversion
                  ? t("homepage-faq-accordion-d-body-specific", {
                      from: convertFromUppercase,
                      to: convertToUppercase,
                    })
                  : t("homepage-faq-accordion-d-body")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1e-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {t("homepage-faq-accordion-e-title")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                {t("homepage-faq-accordion-e-body")}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Container>
  );
}
