import { API_PATH_ANALYTICS } from "@cospex/client/constants";
import useUploads from "@cospex/client/converter/hooks/useUploads";
import {
  getErrorMessage,
  getItemWithExpiry,
  getSourceParams,
  setItemWithExpiry,
} from "@cospex/client/helpers";
import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as z from "zod";

interface FormValues {
  email: string;
}

const useUploaderState = () => {
  const { session } = useAuth();
  const { t, i18n } = useTranslation();
  const { uploads } = useUploads();

  const upload = uploads[0];
  const [showProgress, setShowProgress] = useState(false);
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);

  const { utm_source, utm_medium, utm_campaign, gclid, fbclid, msclkid } =
    getSourceParams();

  const { control, handleSubmit, formState } = useForm<FormValues>({
    ...(!session && {
      resolver: zodResolver(
        z.object({
          email: z.string().email(t("invalid-email")),
        })
      ),
    }),
    mode: "onChange",
    defaultValues: {
      email: session?.email || getItemWithExpiry("userEmail") || "",
    },
  });

  const onSubmitNew = async (data: FormValues) => {
    const oneHour = 1000 * 60 * 60;
    const email = data?.email;
    try {
      if (!upload || !upload.remoteId) {
        throw new Error(t("malformed-upload"));
      }

      setItemWithExpiry("userEmail", email, oneHour);

      const {
        data: { id },
      } = await axios.post("/api/converter/onboarding", {
        email: email,
        language: i18n.language,
        fileId: upload.remoteId,
      });

      setItemWithExpiry("onboardingId", id, oneHour);
      setShowProgress(true);

      axios.post(API_PATH_ANALYTICS, {
        email,
        utm_source,
        utm_medium,
        utm_campaign,
        gclid,
        fbclid,
        msclkid,
      });
    } catch (error) {
      setError(getErrorMessage(error));
    }
  };

  const onSubmitExisting = async () => {
    navigate("/dashboard/overview");
  };

  const onHandleSubmit = handleSubmit(session ? onSubmitExisting : onSubmitNew);
  const hasUpload = Boolean(uploads[0]);

  return {
    control,
    onHandleSubmit,
    formState,
    showProgress,
    error,
    hasUpload,
  };
};

export default useUploaderState;
