import { Box, SxProps } from "@mui/material";
import * as pdfjs from "pdfjs-dist";
import React, { useEffect, useState } from "react";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

if (typeof Promise.withResolvers === "undefined") {
  // @ts-expect-error - polyfilling new feature
  window.Promise.withResolvers = function () {
    let resolve, reject;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
    return { promise, resolve, reject };
  };
}

const PDFImage = ({ file, sx = {} }: { file: File; sx?: SxProps }) => {
  const [imageDataUrl, setImageDataUrl] = useState<string | null>(null);
  useEffect(() => {
    const renderPage = async () => {
      if (!file) return;

      const arrayBuffer = await file.arrayBuffer();

      const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;

      const page = await pdf.getPage(1);

      const desiredWidth = 600;

      const viewport = page.getViewport({ scale: 1 });

      const scale = desiredWidth / viewport.width;

      const scaledViewport = page.getViewport({ scale });

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = scaledViewport.width;
      canvas.height = scaledViewport.height;

      await page.render({ canvasContext: context, viewport: scaledViewport })
        .promise;

      const dataUrl = canvas.toDataURL("image/png");

      setImageDataUrl(dataUrl);
    };

    renderPage();
  }, [file]);

  return (
    <Box sx={{ ...sx, overflow: "hidden", position: "relative" }}>
      {imageDataUrl && (
        <Box
          component="img"
          src={imageDataUrl}
          alt="PDF first page"
          sx={{ width: "100%" }}
        />
      )}
    </Box>
  );
};

export default PDFImage;
