import HomepageUploaderLarge from "@cospex/client/converter/components/HomepageUploaderLarge";
import SectionHeroTitle from "@cospex/client/converter/components/SectionHeroTitle";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";

export default function SectionHeroInnerLarge() {
  return (
    <Grid container direction="row">
      <Grid
        size={{
          xs: 12,
          md: 10,
        }}
        offset={{ xs: 0, md: 1 }}
      >
        <Stack
          direction="column"
          spacing={{
            xs: 2,
            md: 4,
          }}
          justifyContent="center"
          alignItems="center"
        >
          <SectionHeroTitle />
          <HomepageUploaderLarge />
        </Stack>
      </Grid>
    </Grid>
  );
}
