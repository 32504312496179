import Container from "@cospex/client/converter/components/Container";
import WhyChooseUs from "@cospex/client/converter/components/WhyChooseUs";

export default function WhyChooseUsSection() {
  return (
    <Container
      sx={{
        py: 8,
      }}
    >
      <WhyChooseUs />
    </Container>
  );
}
