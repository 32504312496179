import useTranslation from "@cospex/client/hooks/useTranslation";
import { Button } from "@mui/material";

const UploaderButton = ({
  hasUpload,
  compressionFormat,
  sx,
}: {
  hasUpload: boolean;
  compressionFormat: string | null;
  sx?: SxProps;
}) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      size="large"
      disableElevation
      type="submit"
      color="error"
      sx={{
        border: "2px solid #aa3030",
        py: 1.5,
        borderRadius: 2,
        transition: "all 0.7s cubic-bezier(0.23, 1, 0.32, 1)",
        "&:hover": {
          boxShadow: "0 4px 25px rgba(0,0,0,0.38)",
          transform: "scale(1.04)",
        },
        ...sx,
      }}
      fullWidth
      onClick={() => {
        if (!hasUpload) {
          (document.querySelector("#fileInput") as HTMLInputElement).click();
        }
      }}
    >
      {!hasUpload
        ? t("choose-file-button")
        : compressionFormat !== null
        ? t("uploader-button-compress")
        : t("uploader-button")}
    </Button>
  );
};

export default UploaderButton;
