import FakeProgress from "@cospex/client/converter/components/FakeProgress";
import { Portal, Stack } from "@mui/material";

const UploaderLoadingOverlay = ({
  showProgress,
  onComplete,
}: {
  showProgress: boolean;
  onComplete: () => void;
}) => {
  return showProgress ? (
    <Portal>
      <Stack
        sx={{
          p: 6,
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: 999999,
          background: "rgba(0,0,0,0.8)",
          top: 0,
          left: 0,
          color: "white",
        }}
      >
        <FakeProgress onComplete={onComplete} />
      </Stack>
    </Portal>
  ) : null;
};

export default UploaderLoadingOverlay;
