import FileIcon from "@cospex/client/converter/components/FileIcon";
import { getExtensionColor } from "@cospex/client/converter/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  CheckCircleRounded,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

const DefaultConversionPreview = ({ upload }: { upload: Upload }) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
        sx={{
          mb: 4,
        }}
      >
        <CheckCircleRounded sx={{ color: "success.dark" }} />
        <Typography variant="h6" fontWeight={400}>
          {t("payment-file-ready")}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <FileIcon color={getExtensionColor(upload.type)} text={upload.type} />
        <KeyboardDoubleArrowRight sx={{ fontSize: 32 }} />
        <FileIcon
          color={getExtensionColor(upload.convertTo!)}
          text={upload.convertTo!}
        />
      </Stack>
    </>
  );
};

export default DefaultConversionPreview;
