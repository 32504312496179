import { LinkProps } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

LinkBehavior.displayName = "LinkBehavior";

const shadows = [];
for (let i = 0; i < 25; i++) {
  shadows.push(`0px ${i}px ${i}px rgba(0, 0, 0, .12)`);
}

const theme = createTheme({
  shadows,
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: {
          fontSize: ".875rem",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  palette: {
    primary: {
      main: "#2576E0",
      light: "#E5F1FF",
    },
    secondary: {
      main: "#003BD0",
    },
    success: {
      main: "#04D000",
    },
    text: {
      primary: "#07052D",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    h1: {
      fontSize: "3rem",
      fontWeight: "bold",
      color: "#1A0F4E",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "2.5rem",
      color: "#1A0F4E",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "1.5rem",
      color: "#1A0F4E",
    },
    h4: {
      fontWeight: "bold",
      fontSize: "1.5rem",
      color: "#07052D",
    },
    h5: {
      fontWeight: "bold",
      color: "#07052D",
    },
    h6: {
      fontWeight: "bold",
      color: "#07052D",
    },
    body1: {
      color: "#07052D",
    },
    body2: {
      color: "#76757a",
    },
  },
});

export default responsiveFontSizes(theme);
