import Container from "@cospex/client/converter/components/Container";
import PricingComponent from "@cospex/client/converter/components/PricingComponent";

export default function PricingSection() {
  return (
    <Container sx={{ py: 8 }}>
      <PricingComponent
        action={() => {
          document.querySelector("#uploadInput")!.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }}
      />
    </Container>
  );
}
