import { getImageMeta } from "@cospex/client/converter/helpers";
import { useEffect, useState } from "react";

const useImageMeta = (upload: Upload) => {
  const [imageMeta, setImageMeta] = useState<ImageMeta | null | undefined>();

  useEffect(() => {
    if (upload.baseType !== "image") return;
    const tryGetImageMeta = async () => {
      try {
        const meta = await getImageMeta(upload.file);
        setImageMeta(meta);
      } catch (error) {
        console.error(error);
        setImageMeta(null);
      }
    };

    tryGetImageMeta();
    return () => {
      if (imageMeta) {
        URL.revokeObjectURL(imageMeta.url);
      }
    };
  }, [upload.file]);

  return imageMeta;
};

export default useImageMeta;
