import axios from "axios";
import { openDB } from "idb";
import React, { createContext, useEffect, useRef, useState } from "react";

import useAuth from "../../hooks/useAuth";
import { getConversionTargets, getOperationFromQuery } from "../helpers";

const randomId = () => {
  return Math.random().toString(36).substr(2, 9);
};

interface UploadsContextType {
  uploads: Upload[];
  startUpload: (file: File, type: OperationType) => void;
  stopUpload: (file: string) => void;
  removeUpload: (file: string) => void;
  updateConversion: (file: string, conversion: ConversionType) => void;
  removeAllUploads: () => void;
  uploadsRestored: Boolean;
}

const UploadsContext = createContext<UploadsContextType>({
  uploads: [],
  startUpload: () => {},
  stopUpload: () => {},
  removeUpload: () => {},
  updateConversion: () => {},
  removeAllUploads: () => {},
  uploadsRestored: false,
});

export const UploadsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { session } = useAuth();
  const [uploads, setUploads] = useState<Upload[]>([]);
  const uploadsRef = useRef<Upload[]>([]);
  const [uploadsRestored, setUploadsRestored] = useState(false);

  /**
   * This useEffect hook is used to restore uploads from the IndexedDB.
   * It fetches all stored uploads and sets their progress to -1 if they are not completed or cancelled.
   * Finally, it updates the state with these restored uploads.
   */
  useEffect(() => {
    (async () => {
      try {
        const db = await openDB("uploadsDB", 1, {
          upgrade(db: any) {
            db.createObjectStore("uploads");
          },
        });
        const savedUploads = await db.getAll("uploads");
        savedUploads.forEach((upload: Upload) => {
          if (upload.progress !== 100 && !upload.cancelToken) {
            upload.progress = -2;
          }
        });
        setUploads(savedUploads);
      } catch (error) {
        console.error("Error restoring uploads:", error);
      }
      setUploadsRestored(true);
    })();
  }, []);

  /**
   * This useEffect hook is used to keep the uploadsRef current with the state.
   * It also updates or adds the upload in the IndexedDB.
   */
  useEffect(() => {
    if (!uploadsRestored) return;
    uploadsRef.current = uploads;
    (async () => {
      const db = await openDB("uploadsDB", 1);
      await db.clear("uploads");
      uploads.forEach(async (upload) => {
        const toPut = { ...upload } as any;
        delete toPut.cancelToken;
        await db.put("uploads", toPut, upload.id);
      });
    })();
  }, [uploads]);

  /**
   * Find an upload by its id.
   */
  const upload = (id: string) => {
    return uploadsRef.current.find((u) => u.id === id);
  };

  // Expose uploads to the window object for debugging
  (window as any).uploads = uploads;

  /**
   * Upload a file to a given URL.
   * It also updates the progress of the upload.
   */
  const putFile = async (
    url: string,
    file: File,
    cancelToken: any,
    cb: (progress: number) => void
  ) => {
    let completed = false;
    await axios.put(url, file, {
      headers: { "Content-Type": file.type },
      onUploadProgress: (param) => {
        if (completed) return;
        const { loaded, total } = param;
        let progress = total ? loaded / total : 0;
        progress = Math.round(progress * 100);
        cb(progress);
      },
      cancelToken: cancelToken.token,
    });

    completed = true;
    cb(100);
  };

  /**
   * Handle errors during the upload process.
   * It checks if the error is due to cancellation and updates the upload accordingly.
   */
  const handleError = (id: string, error: any) => {
    if (axios.isCancel(error)) {
      updateUpload(id, { progress: -1, cancelToken: null });
    } else {
      console.error("Upload failed:", error);
      updateUpload(id, { progress: -1 });
    }
  };

  /**
   * Handle the upload process for a new user.
   * It fetches a presigned URL, starts the upload, and handles the completion or failure of the upload.
   */
  const newUserUpload = async (id: string) => {
    let fastForwardTimeout: NodeJS.Timeout | null = null;
    try {
      const uploadItem = upload(id);
      if (!uploadItem) return;
      const { file, operationType, convertTo } = uploadItem;

      const {
        data: { url: s3Url, id: remoteId },
      } = await axios.post("/api/converter/onboarding/files", {
        contentType: file.type,
        filename: file.name,
        operation: {
          type: operationType,
          outputFormat: convertTo,
        },
      });

      updateUpload(uploadItem.id, {
        s3Url,
        remoteId,
      });

      let uploadFastForwarded = false;

      fastForwardTimeout = setTimeout(() => {
        uploadFastForwarded = true;
        updateUpload(id, { progress: 100 });
      }, 5000);

      await putFile(
        s3Url,
        uploadItem.file,
        uploadItem.cancelToken,
        (progress) => {
          // Do not update dangling or cancelled uploads
          const u = upload(id);
          if (!u || u.progress < 0) return;
          updateUpload(id, {
            progress: uploadFastForwarded ? 100 : progress,
            realProgress: progress,
          });
        }
      );
      updateUpload(id, { progress: 100 });
    } catch (error) {
      handleError(id, error);
    } finally {
      if (fastForwardTimeout) {
        clearTimeout(fastForwardTimeout);
      }
    }
  };

  /**
   * Handle the upload process for an existing user.
   * It fetches a presigned URL, starts the upload, and handles the completion or failure of the upload.
   */
  const existingUserUpload = async (id: string) => {
    const uploadItem = upload(id);
    if (!uploadItem) return;
    const { file } = uploadItem;
    const {
      data: { url: s3Url, filename: remoteFilename },
    } = await axios.get(`/api/converter/upload-url`, {
      params: { contentType: file.type, filename: file.name },
    });

    updateUpload(uploadItem.id, {
      s3Url,
      remoteFilename,
    });

    try {
      await putFile(
        s3Url,
        uploadItem.file,
        uploadItem.cancelToken,
        (progress) => {
          const u = upload(id);
          if (!u || u.progress < 0) return; // do not update danngling uploads if they've been cancelled
          updateUpload(id, {
            progress,
            realProgress: progress,
          });
        }
      );
      updateUpload(id, { progress: 100 });
    } catch (error) {
      handleError(id, error);
    }
  };

  /**
   * Start the upload process.
   * It creates a new upload, adds it to the state and IndexedDB, and starts the upload process.
   */
  const startUpload = async (file: File, type: OperationType) => {
    const cancelToken = axios.CancelToken.source();
    const querySelection = getOperationFromQuery().convertTo;
    const conversionTargets = getConversionTargets(file.type);
    const defaultSelection = conversionTargets?.[0];
    const defaultConvertTo = querySelection || defaultSelection || null;

    const id = randomId();
    const newUpload: Upload = {
      id,
      file,
      progress: 0,
      s3Url: null,
      remoteId: null,
      remoteFilename: null,
      realProgress: 0,
      filename: null,
      cancelToken,
      conversionTargets: conversionTargets || [],
      baseType: file.type.split("/")[0],
      type: file.name.split(".").slice(-1)[0],
      convertFrom: file.type.split("/")[1],
      operationType: type,
      convertTo: defaultConvertTo,
    };

    addUpload(newUpload);

    if (!session) {
      await newUserUpload(newUpload.id);
    } else {
      await existingUserUpload(newUpload.id);
    }
  };

  /**
   * Stop an ongoing upload.
   * It cancels the upload and updates the state and IndexedDB.
   */
  const stopUpload = (id: string) => {
    const u = upload(id);
    if (!u) return;
    u.cancelToken?.cancel("Upload cancelled by user");
    updateUpload(id, { progress: -2, cancelToken: null });
  };

  /**
   * Update an upload in the state and IndexedDB.
   */
  const updateUpload = (id: string, changes: Partial<Upload>) => {
    const newUploads = uploadsRef.current.map((upload) =>
      upload.id === id ? { ...upload, ...changes } : upload
    );

    uploadsRef.current = newUploads;
    setUploads(newUploads);
  };

  /**
   * Add a new upload to the state.
   * Addition to the IndexedDB is handled by the useEffect hook.
   */
  const addUpload = (upload: Upload) => {
    const newUploads = [...uploadsRef.current, upload];
    uploadsRef.current = newUploads;

    setUploads(newUploads);
  };

  /**
   * Remove an upload from the state and IndexedDB.
   * If the upload is not completed, it stops the upload before removing it.
   */
  const removeUpload = (id: string) => {
    const u = upload(id);
    if (!u || u.progress < 100) stopUpload(id);
    const newUploads = uploadsRef.current.filter((upload) => upload.id !== id);

    uploadsRef.current = newUploads;

    setUploads(newUploads);
  };

  /**
   * Update the conversion type of an upload.
   */
  const updateConversion = (id: string, conversion: ConversionType) => {
    updateUpload(id, { convertTo: conversion });
  };

  /**
   * Remove all uploads from the state and IndexedDB.
   * It cancels all ongoing uploads before removing them.
   */
  const removeAllUploads = () => {
    uploads.forEach((u) => {
      u.cancelToken?.cancel("Upload removed");
    });
    uploadsRef.current = [];
    setUploads([]);
  };

  const prevSessionRef = useRef(session);

  useEffect(() => {
    if (prevSessionRef.current && session === null) {
      removeAllUploads();
    }
    prevSessionRef.current = session;
  }, [session]);

  return (
    <UploadsContext.Provider
      value={{
        uploads,
        startUpload,
        stopUpload,
        removeUpload,
        updateConversion,
        removeAllUploads,
        uploadsRestored,
      }}
    >
      {children}
    </UploadsContext.Provider>
  );
};

export default UploadsContext;
