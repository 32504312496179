import ReviewCarousel from "@cospex/client/components/ReviewCarousel";
import ConverterPaymentPreview from "@cospex/client/converter/components/ConverterPaymentPreview";
import PaymentInfo from "@cospex/client/converter/components/PaymentCardInfo";
import Stepper from "@cospex/client/converter/components/Stepper";
import WhyChooseUs from "@cospex/client/converter/components/WhyChooseUs";
import { OPERATION } from "@cospex/client/converter/constants";
import useUploads from "@cospex/client/converter/hooks/useUploads";
import PaymentForm from "@cospex/client/forms/payment";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  Alert,
  Box,
  Card,
  CardProps,
  Container,
  Grid,
  Stack,
} from "@mui/material";
import { SpringValue, a, useTransition } from "@react-spring/web";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import PricingSection from "./SectionPricing";
import UploadsCounter from "./UploadsCounter";

const CardContainer = ({
  children,
  animStyle,
  cardProps,
}: {
  children: React.ReactNode;
  animStyle: { opacity: SpringValue<number> };
  cardProps: CardProps;
}) => {
  return (
    <a.div style={animStyle}>
      <Card {...cardProps}>{children}</Card>
    </a.div>
  );
};

const Payment = () => {
  const { uploads, uploadsRestored, removeAllUploads } = useUploads();
  const [cards] = useState<number[]>([0, 1, 2]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const email = localStorage.getItem("userEmail");
  const [searchParams] = useSearchParams();
  const identifier = searchParams.get("identifier");
  const upload = uploads[0];
  const noDetailsError = (!email || !uploads.length) && !identifier;

  useEffect(() => {
    if (uploadsRestored && uploads.length === 0) {
      navigate("/");
    }
  }, [uploadsRestored]);

  const cardTransitions = useTransition(cards, {
    keys: (item) => item,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    trail: 200,
  });

  if (noDetailsError) {
    return (
      <Container
        sx={{
          mb: 2,
        }}
      >
        <Alert severity="error">{t("error-uploading")}</Alert>
      </Container>
    );
  }

  return (
    <>
      <Container sx={{ pb: "3rem" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} sx={{ "& > div": { height: "100%" } }}>
            {cardTransitions((animStyle, item) =>
              item === 0 ? (
                <CardContainer
                  animStyle={animStyle}
                  cardProps={{
                    elevation: 18,
                    sx: {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                      boxShadow: "none",
                      borderColor: "primary.main",
                      borderWidth: 1,
                      borderStyle: "solid",
                    },
                  }}
                >
                  <PaymentForm
                    onPaymentSuccessRedirectPath={
                      upload?.operationType === OPERATION.CONVERSION
                        ? "/dashboard/overview"
                        : "/dashboard/compression"
                    }
                    onPaymentSuccess={() => {
                      if (uploads.length > 1) {
                        throw new Error(
                          "More than 1 upload found during payment."
                        );
                      }
                      removeAllUploads();
                    }}
                  />
                </CardContainer>
              ) : null
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack gap={3}>
              {cardTransitions((style, item) =>
                item === 1 ? (
                  <CardContainer
                    animStyle={style}
                    cardProps={{
                      variant: "outlined",
                      sx: {
                        p: 4,
                      },
                    }}
                  >
                    <Stepper />
                    <ConverterPaymentPreview />
                  </CardContainer>
                ) : null
              )}
              {cardTransitions((style, item) =>
                item === 2 ? (
                  <CardContainer
                    animStyle={style}
                    cardProps={{
                      variant: "outlined",
                      sx: {
                        p: 4,
                      },
                    }}
                  >
                    <PaymentInfo />
                  </CardContainer>
                ) : null
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <UploadsCounter />
      <Box
        sx={{
          overflow: "hidden",
          pb: {
            xs: 2,
            md: 4,
          },
        }}
      >
        <Container>
          <ReviewCarousel />
        </Container>
      </Box>
      <Container
        sx={{
          py: 8,
        }}
      >
        <WhyChooseUs />
      </Container>
      <PricingSection />
    </>
  );
};

export default Payment;
