import Container from "@cospex/client/converter/components/Container";
import { getOperationFromQuery } from "@cospex/client/converter/helpers";
import HomeImage11 from "@cospex/client/converter/img/home_image_1_1.svg";
import HomeImage12 from "@cospex/client/converter/img/home_image_1_2.svg";
import HomeImage13 from "@cospex/client/converter/img/home_image_1_3.svg";
import HomeImage21 from "@cospex/client/converter/img/home_image_2_1.svg";
import HomeImage22 from "@cospex/client/converter/img/home_image_2_2.svg";
import HomeImage23 from "@cospex/client/converter/img/home_image_2_3.svg";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useParallax } from "react-scroll-parallax";

export default function StepsSection() {
  const { t } = useTranslation();
  const { convertFromUppercase, convertToUppercase, specificConversion } =
    getOperationFromQuery();

  const steps = [
    specificConversion
      ? t("homepage-step-a-specific", { file: convertFromUppercase })
      : t("homepage-step-a"),
    t("homepage-step-b"),
    specificConversion
      ? t("homepage-step-c-specific", { file: convertToUppercase })
      : t("homepage-step-c"),
  ];
  return (
    <Container
      backgroundColor="primary.main"
      sx={{
        overflow: "hidden",
        py: {
          md: 8,
          xs: 4,
        },
        "& p": {
          color: "white",
        },
        "& h5": {
          color: "white",
        },
        "& h3": {
          color: "white",
        },
      }}
    >
      <Grid
        container
        spacing={8}
        direction="row"
        alignItems="center"
        sx={{
          mb: {
            md: 8,
            xs: 4,
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "auto",
              aspectRatio: "580/483",
              position: "relative",
              transform: "scale(.92)",
            }}
          >
            <Box
              ref={
                useParallax({
                  speed: -14,
                }).ref
              }
              component="img"
              src={HomeImage11}
              alt="Hero Image"
              sx={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            />
            <Box
              ref={
                useParallax({
                  speed: -8,
                }).ref
              }
              component="img"
              src={HomeImage12}
              alt="Hero Image"
              sx={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            />
            <Box
              ref={
                useParallax({
                  speed: -4,
                }).ref
              }
              component="img"
              src={HomeImage13}
              alt="Hero Image"
              sx={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack gap={2}>
            <Typography variant="h5">
              {specificConversion
                ? t("homepage-description-a-title-specific", {
                    from: convertFromUppercase,
                    to: convertToUppercase,
                  })
                : t("homepage-description-a-title")}
            </Typography>
            <Typography variant="body1">
              {specificConversion
                ? t("homepage-description-a-body-specific", {
                    from: convertFromUppercase,
                    to: convertToUppercase,
                  })
                : t("homepage-description-a-body")}
            </Typography>
            <Box>
              <Box
                onClick={() => {
                  document.querySelector("#uploadInput")!.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
                sx={{
                  color: "white",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: 600,
                }}
              >
                {t("homepage-description-b-title")}
              </Box>
            </Box>
            <Typography variant="h5">
              {t("homepage-description-c-title")}
            </Typography>
            <Typography variant="body2">
              {t("homepage-description-c-body")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={8} direction="row" alignItems="center">
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h3" mb={4}>
              {specificConversion
                ? t("how-to-convert-notag", {
                    from: convertFromUppercase,
                    to: convertToUppercase,
                  })
                : t("homepage-steps-title")}
            </Typography>
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: 40,
                      height: 40,
                      borderRadius: "50%",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mr: 1,
                    }}
                  >
                    {index + 1}
                  </Box>
                  <Typography>{step}</Typography>
                </Box>
                {index < steps.length - 1 && (
                  <Divider
                    sx={{
                      flex: 1,
                      height: 20,
                      backgroundColor: "white",
                      mr: 1,
                      width: "1px",
                      marginLeft: "20px",
                    }}
                    orientation="vertical"
                  />
                )}
              </React.Fragment>
            ))}
            <Button
              variant="outlined"
              disableElevation
              size="large"
              onClick={() => {
                document.querySelector("#uploadInput")!.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }}
              sx={{
                backgroundColor: "white",
                color: "primary.main",
                borderColor: "white",
                mt: 4,
                "&:hover": {
                  backgroundColor: "white",
                  borderColor: "white",
                },
              }}
            >
              {t("homepage-steps-button")}
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "auto",
              aspectRatio: "580/483",
              position: "relative",
              transform: "scale(.92)",
            }}
          >
            <Box
              ref={
                useParallax({
                  speed: -14,
                }).ref
              }
              component="img"
              src={HomeImage21}
              alt="Hero Image"
              sx={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            />
            <Box
              ref={
                useParallax({
                  speed: -8,
                }).ref
              }
              component="img"
              src={HomeImage22}
              alt="Hero Image"
              sx={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            />
            <Box
              ref={
                useParallax({
                  speed: -4,
                }).ref
              }
              component="img"
              src={HomeImage23}
              alt="Hero Image"
              sx={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
