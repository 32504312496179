import { withTransientProps } from "@cospex/client/helpers";
import usePrices from "@cospex/client/hooks/usePrices";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { ArrowRightAlt, CheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  styled,
} from "@mui/material";

const StyledBox = styled(Stack, withTransientProps)<{ $isTrial?: boolean }>`
  ${({ theme, $isTrial }) => `
    border-radius: 10px;
    padding: 2.5rem;
    width: 100%;
    row-gap: .5rem;
    ${
      $isTrial
        ? `
            background: ${theme.palette.primary.main};
            .MuiTypography-root  {
              color: white;
            }
            .MuiSvgIcon-root {
              color: white;
            }
            .MuiDivider-root {
              border-color: rgba(255,255,255,.2);
            }
            .MuiButton-root {
              background: white;
              color: black;
              &:hover {
                background: ${theme.palette.grey[200]};
              }
            }
          `
        : `
            .MuiSvgIcon-root {
              color: ${theme.palette.primary.main};
            }
            border: 1px solid ${theme.palette.grey[300]};
          `
    }
  `}
`;

const PlanContentBox = ({
  heading,
  desc,
  descMinor,
}: {
  heading: string;
  desc: string;
  descMinor: string;
}) => {
  return (
    <>
      <Typography variant="h4" sx={{ mb: 1 }}>
        {heading}
      </Typography>
      <Typography variant="h4" display="flex" alignItems="center">
        {desc}
        <span
          style={{
            paddingLeft: ".25rem",
            fontSize: "1rem",
            fontWeight: 400,
            opacity: 0.8,
            transform: "translateY(1px)",
          }}
        >
          / {descMinor}
        </span>
      </Typography>
    </>
  );
};

const ItemStack = ({ items }: { items: string[] }) => {
  return (
    <List dense={true}>
      {items.map((item: string, index: number) => {
        return (
          <ListItem key={index} sx={{ pl: 0 }}>
            <ListItemIcon sx={{ minWidth: "auto", mr: 2 }}>
              <CheckCircle />
            </ListItemIcon>
            <ListItemText primary={item} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default function PriceComponent({ action }: { action?: () => void }) {
  const { t } = useTranslation();

  const trailItems: string[] = [
    t("trial-advantage-one"),
    t("trial-advantage-two"),
    t("trial-advantage-three"),
  ];

  const monthlyItems: string[] = [
    t("monthly-plan-one"),
    t("monthly-plan-two"),
    t("monthly-plan-three"),
    t("monthly-plan-four"),
    t("monthly-plan-five"),
  ];
  const prices = usePrices();
  return (
    <Stack
      gap={6}
      alignItems="center"
      sx={{
        textAlign: "center",
      }}
    >
      <Typography variant="h2">{t("homepage-pricing-title")}</Typography>
      <Typography variant="body1" sx={{ maxWidth: 1050 }}>
        {t("homepage-pricing-description")}
      </Typography>
      <Grid
        container
        spacing={5}
        justifyContent="center"
        sx={{
          position: "relative",
        }}
      >
        <Grid item xs={12} md={5} display="flex" alignItems="stretch">
          <StyledBox $isTrial>
            <PlanContentBox
              heading={t("trial-offer")}
              desc={prices.localeSubscriptionPrice}
              descMinor={t("trial-offer-duration")}
            />
            <ItemStack items={trailItems} />
            <Divider sx={{ mb: 2 }} />
            <Button sx={{ p: 1.5 }} onClick={() => action?.()}>
              {t("trial-offer-cta")}
            </Button>
          </StyledBox>
        </Grid>
        <Box
          sx={{
            width: 96,
            height: 96,
            borderRadius: "96px",
            background: "rgba(255,255,255,.76)",
            backdropFilter: "blur(10px)",
            position: "absolute",
            top: "calc(20px + 50%)",
            left: "calc(20px + 50%)",
            transform: "translate(-50%, -50%)",
            boxShadow: 6,
            borderTop: "2px solid white",
            p: 1,
          }}
        >
          <ArrowRightAlt sx={{ fontSize: 56, mt: -1 }} />
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, mt: -2.5, fontSize: 12 }}
          >
            {t("after-trial")}
          </Typography>
        </Box>
        <Grid item xs={12} md={5} display="flex" alignItems="stretch">
          <StyledBox>
            <PlanContentBox
              heading={t("monthly-plan")}
              desc={prices.localeRebillPrice}
              descMinor={t("monthly-plan-duration")}
            />
            <ItemStack items={monthlyItems} />
          </StyledBox>
        </Grid>
      </Grid>
    </Stack>
  );
}
