import { API_PATH_LOGIN } from "@cospex/client/constants";
import LoginGraphic from "@cospex/client/converter/img/login_graphic.svg";
import TextInput from "@cospex/client/forms/TextInput";
import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { zodResolver } from "@hookform/resolvers/zod";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Card,
  Container,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

const validationSchema = z.object({
  email: z.string().email("login-email-invalid"),
  password: z.string().min(4, "login-password-min"),
});

type LoginData = {
  email: string;
  password: string;
};

const Login = () => {
  const { t } = useTranslation();
  const { control, handleSubmit, formState } = useForm<LoginData>({
    resolver: zodResolver(validationSchema),
  });
  const { signin } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect") || "/dashboard/overview";

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const mutation = useMutation({
    mutationFn: ({ email, password }: LoginData) => {
      return axios.post(
        API_PATH_LOGIN,
        { email, password },
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      );
    },
    onSuccess: (res, { email }) => {
      const token = res?.data?.token || "";
      const refreshToken = res?.data?.refresh_token || "";
      signin(email, token, refreshToken);
      navigate(redirect, { replace: true });
    },
    onError(res: any) {
      const error = res.response?.data?.error || res.message;
      setError(error);
    },
  });

  return (
    <Container
      sx={{
        minHeight: "66vh",
        py: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
          direction="column"
        >
          <Typography variant="h2" gutterBottom>
            {t("login-title")}
          </Typography>
          <Box
            component="img"
            src={LoginGraphic}
            sx={{
              width: "100%",
              height: "auto",
              maxWidth: 500,
              display: {
                xs: "none",
                md: "block",
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              p: 4,
              maxWidth: 450,
              width: "100%",
            }}
            elevation={8}
          >
            <Typography variant="h4" gutterBottom>
              {t("login-subtitle")}
            </Typography>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
              onSubmit={handleSubmit((data: LoginData) =>
                mutation.mutate(data)
              )}
            >
              <TextInput
                name="email"
                control={control}
                formState={formState}
                fullWidth
                label={t("login-email")}
              />
              <TextInput
                name="password"
                control={control}
                formState={formState}
                fullWidth
                label={t("login-password")}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />

              <LoadingButton
                type="submit"
                loading={mutation.isLoading}
                variant="contained"
                disableElevation
              >
                <span>{t("login-now-button")}</span>
              </LoadingButton>
              {error && <Alert severity="error">{t(error)}</Alert>}
              <Typography variant="body2">
                {t("login-forgot-password")}{" "}
                <Link to="/reset">{t("login-reset-password")}</Link>
              </Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
