import { OPERATION } from "@cospex/client/converter/constants";
import useUploads from "@cospex/client/converter/hooks/useUploads";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const generateIncreasingRandomNumbers = (): number[] => {
  const arr: number[] = [];
  let currentMax = 0;

  for (let i = 0; i < 4; i++) {
    const newNum = Math.floor(Math.random() * (101 - currentMax)) + currentMax;
    arr.push(newNum);
    currentMax = newNum + 1;
  }

  arr[arr.length - 1] = 100;

  return arr;
};

const FakeProgress = ({ onComplete }: { onComplete: () => void }) => {
  const { uploads } = useUploads();
  const { t } = useTranslation();
  const [conversionProgress, setConversionProgress] = useState(0);
  const upload = uploads[0]!;
  const [conversionMessage, setConversionMessage] = useState(
    upload.operationType === OPERATION.CONVERSION
      ? t("conversion-message-1")
      : t("compression-message-1")
  );
  useEffect(() => {
    const progressPoints = generateIncreasingRandomNumbers();
    let currentProgress = 0;
    const interval = setInterval(() => {
      if (progressPoints.length === 0) {
        onComplete();
        clearInterval(interval);
        return;
      }
      currentProgress = progressPoints.shift()!;
      if (currentProgress > 70 && currentProgress < 99) {
        setConversionMessage(t("conversion-message-2"));
      }
      if (currentProgress === 100) {
        setConversionMessage(t("conversion-message-3"));
      }
      setConversionProgress(currentProgress);
    }, Math.random() * 1000 + 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: 300,
        fontWeight: "bold",
        width: "100%",
        gap: 2,
      }}
    >
      {conversionMessage}
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          value={conversionProgress}
          color="primary"
          size={55}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="white"
          >{`${conversionProgress}%`}</Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default FakeProgress;
