import HomepageUploaderCompact from "@cospex/client/converter/components/HomepageUploaderCompact";
import SectionHeroTitle from "@cospex/client/converter/components/SectionHeroTitle";
import HomeImage01 from "@cospex/client/converter/img/home_hero.svg";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { CheckCircle } from "@mui/icons-material";
import { Box, Grid, Theme, Typography, useMediaQuery } from "@mui/material";

function BulletPointBox() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        pt: {
          xs: 4,
          md: 0,
        },
        px: {
          xs: 2,
          md: 0,
        },
        pb: {
          xs: 2,
          md: 0,
        },
        "> .MuiBox-root": {
          display: "flex",
          alignItems: "center",
          gap: 2,
          textAlign: "left",
        },
        " .MuiTypography-root": {
          fontSize: 14,
        },
        ".MuiSvgIcon-root": {
          color: "primary.main",
        },
      }}
    >
      <Box>
        <CheckCircle />
        <Typography>{t("homepage-chip-a")}</Typography>
      </Box>
      <Box>
        <CheckCircle />
        <Typography>{t("homepage-chip-b")}</Typography>
      </Box>
      <Box>
        <CheckCircle />
        <Typography>{t("homepage-chip-c")}</Typography>
      </Box>
    </Box>
  );
}

export default function SectionHeroInnerDefault() {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <Grid
      container
      spacing={{
        xs: 2,
        md: 6,
      }}
      direction="row"
    >
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          textAlign: "center",
          display: "flex",
          gap: 2,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SectionHeroTitle />
          <Typography
            variant="subtitle1"
            sx={{
              mb: {
                md: 4,
              },
              fontSize: 14,
            }}
          >
            {t("homepage-subtitle")}
          </Typography>
          {isDesktop ? <BulletPointBox /> : null}
        </Box>
        <Box
          component="img"
          src={HomeImage01}
          alt="Hero Image"
          sx={{
            maxWidth: 400,
            height: "auto",
            display: {
              xs: "none",
              md: "block",
            },
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={7}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <HomepageUploaderCompact
          sx={{
            flex: 1,
            minHeight: 320,
          }}
        />
        {!isDesktop ? <BulletPointBox /> : null}
      </Grid>
    </Grid>
  );
}
