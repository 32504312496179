import ReviewCarousel from "@cospex/client/components/ReviewCarousel";
import Container from "@cospex/client/converter/components/Container";

export default function ReviewSection() {
  return (
    <Container
      sx={{
        overflow: "hidden",
        pb: {
          lg: 8,
          md: 4,
          xs: 2,
        },
      }}
    >
      <ReviewCarousel />
    </Container>
  );
}
