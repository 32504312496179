import PricingComponent from "@cospex/client/converter/components/PricingComponent";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Page() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        py: {
          md: 12,
          sm: 8,
          xs: 6,
        },
        px: {
          md: 8,
          sm: 4,
          xs: 2,
        },
      }}
    >
      <PricingComponent
        action={() => {
          navigate("/");
        }}
      />
    </Box>
  );
}
