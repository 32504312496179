import { Box } from "@mui/material";

export default function FileIcon({
  color,
  text,
  sx,
}: {
  color: string;
  text: string;
  sx?: any;
}) {
  return (
    <Box
      sx={{
        ...sx,
        position: "relative",
      }}
    >
      <Box
        component="svg"
        height="64px"
        version="1.1"
        viewBox="0 0 32 32"
        width="64px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 12.5V9.5L14 2.5H3C1.9 2.5 1 3.4 1 4.5V27.5C1 28.6 1.9 29.5 3 29.5H18C19.1 29.5 20 28.6 20 27.5V25.5H19V27.5C19 28 18.5 28.5 18 28.5H3C2.5 28.5 2 28 2 27.5V4.5C2 4 2.4 3.5 3 3.5H13V8.5C13 9.6 13.9 10.5 15 10.5H19V12.5H20ZM15 9.5C14.4 9.5 14 9 14 8.5V4L18.7 9.5H15Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 12.5H11C9.3 12.5 8 13.8 8 15.5V22.5C8 24.2 9.3 25.5 11 25.5H28C29.7 25.5 31 24.2 31 22.5V15.5C31 13.9 29.7 12.5 28 12.5ZM30 22.5C30 23.6 29.1 24.5 28 24.5H11C9.9 24.5 9 23.6 9 22.5V15.5C9 14.4 9.9 13.5 11 13.5H28C29.1 13.5 30 14.4 30 15.5V22.5Z"
          fill={color}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 26,
          left: 16,
          width: 47,
          height: 25,
          textAlign: "center",
          color,
          fontWeight: 500,
          fontSize: text.length > 3 ? 12 : 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {text.toUpperCase()}
      </Box>
    </Box>
  );
}
