import { getOperationFromQuery } from "@cospex/client/converter/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Typography } from "@mui/material";

export default function CTASection() {
  const { convertFromUppercase, convertToUppercase, specificConversion } =
    getOperationFromQuery();
  const { t } = useTranslation();
  return specificConversion ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: {
          md: "row",
          xs: "column",
        },
        gap: 2,
        backgroundColor: "primary.main",
        textAlign: "center",
        px: 2,
        mb: 4,
        py: {
          md: 6,
          sm: 4,
          xs: 3,
        },
      }}
    >
      <Typography variant="h4" sx={{ color: "white" }}>
        {t("convert-in-seconds-specific", {
          from: convertFromUppercase,
          to: convertToUppercase,
        })}
      </Typography>
      <Box
        sx={{
          backgroundColor: "white",
          color: "primary.main",
          borderColor: "white",
          fontSize: 16,
          borderRadius: 2,
          py: 2,
          px: 4,
          cursor: "pointer",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "white",
            borderColor: "white",
          },
        }}
        onClick={() => {
          document.querySelector("#uploadInput")!.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }}
      >
        {t("upload-your-file")}
      </Box>
    </Box>
  ) : null;
}
