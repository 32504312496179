import Filigram from "@cospex/client/converter/components/Filigram";
import UniversalConversionPreview from "@cospex/client/converter/components/UniversalConversionPreview";
import { getExtensionColor } from "@cospex/client/converter/helpers";
import useImageMeta from "@cospex/client/converter/hooks/useImageMeta";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Stack, Typography } from "@mui/material";

const ImageConversionPreview = ({ upload }: { upload: Upload }) => {
  const { t } = useTranslation();
  const imageMeta = useImageMeta(upload);

  if (upload.baseType !== "image" || imageMeta === undefined) return null;

  // Not all images can be decoded, if so we show the universal preview
  if (imageMeta === null) {
    return <UniversalConversionPreview upload={upload} />;
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
        sx={{
          mb: 2,
        }}
      >
        <Typography variant="h6" fontWeight={400}>
          {t("your-image-is-ready")}
        </Typography>
      </Stack>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            borderRadius: 1,
            p: 1,
            position: "relative",
            backgroundImage: `linear-gradient(90deg, silver 50%, transparent 50%), 
                              linear-gradient(90deg, silver 50%, transparent 50%), 
                              linear-gradient(0deg, silver 50%, transparent 50%), 
                              linear-gradient(0deg, silver 50%, transparent 50%)`,
            backgroundRepeat: "repeat-x, repeat-x, repeat-y, repeat-y",
            backgroundSize: "15px 2px, 15px 2px, 2px 15px, 2px 15px",
            backgroundPosition:
              "left top, right bottom, left bottom, right top",
            animation: "border-dance 1s infinite linear",
            "@keyframes border-dance": {
              "0%": {
                backgroundPosition:
                  "left top, right bottom, left bottom, right top",
              },
              "100%": {
                backgroundPosition:
                  "left 15px top, right 15px bottom, left bottom 15px, right top 15px",
              },
            },
          }}
        >
          <Filigram />
          <Box
            sx={{
              position: "absolute",
              px: 1,
              py: 0.5,
              color: "white",
              borderRadius: 1,
              textTransform: "uppercase",
              backgroundColor: getExtensionColor(upload.convertTo || ""),
              bottom: 0,
              right: 0,
            }}
          >
            {upload.convertTo}
          </Box>

          <Box
            component="img"
            src={imageMeta.url}
            alt={upload.file.name}
            sx={{
              pointerEvents: "none",
              userSelect: "none",
              height: "auto",
              maxHeight: 180,
              minHeight: 100,
              objectFit: "contain",
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default ImageConversionPreview;
