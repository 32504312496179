import {
  ArrowCircleDownRounded,
  FolderOpen,
  Https,
  MarkEmailRead,
} from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PaymentInfo = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="h6"
        fontWeight={400}
        sx={{ mb: 2, textAlign: "center" }}
      >
        {t("payment-summary-title")}
      </Typography>
      <Box
        component="ul"
        sx={{
          m: 0,
          listStyle: "none",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <li>
          <Stack direction="row" alignItems="center" gap={1}>
            <ArrowCircleDownRounded sx={{ color: "primary.main" }} />
            <Typography variant="body2">
              {t("payment-receive-list-one")}
            </Typography>
          </Stack>
        </li>
        <li>
          <Stack direction="row" alignItems="center" gap={1}>
            <MarkEmailRead sx={{ color: "primary.main" }} />
            <Typography variant="body2">
              {t("payment-receive-list-two")}
            </Typography>
          </Stack>
        </li>
        <li>
          <Stack direction="row" alignItems="center" gap={1}>
            <FolderOpen sx={{ color: "primary.main" }} />
            <Typography variant="body2">
              {t("payment-receive-list-three")}
            </Typography>
          </Stack>
        </li>
        <li>
          <Stack direction="row" alignItems="center" gap={1}>
            <Https sx={{ color: "primary.main" }} />
            <Typography variant="body2">
              {t("payment-receive-list-four")}
            </Typography>
          </Stack>
        </li>
      </Box>
    </>
  );
};

export default PaymentInfo;
