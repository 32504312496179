import { CounterContainer } from "@cospex/client/components/Counter";
import { Box, Stack } from "@mui/material";
import { animated, useTransition } from "@react-spring/web";
import React from "react";
import { useTranslation } from "react-i18next";

const UploadsCounter = () => {
  return (
    <CounterContainer>
      {(count) => <CounterDisplay count={count} />}
    </CounterContainer>
  );
};

const CounterDisplay = ({ count }: { count: string }) => {
  const arr = count.split("");

  return (
    <Stack
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        backgroundColor: "primary.light",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: 20,
        mb: {
          xs: 2,
          sm: 4,
          md: 8,
        },
        py: {
          xs: 2,
          sm: 3,
          md: 6,
        },
        gap: {
          xs: 1,
          md: 2,
        },
      }}
    >
      <DigitStack arr={arr} />
      <TextDisplay />
    </Stack>
  );
};

const DigitStack = ({ arr }: { arr: string[] }) => {
  return (
    <Stack
      direction="row"
      sx={{
        gap: {
          xs: 1,
          md: 2,
        },
      }}
    >
      {arr.map((digit, i) => (
        <DigitBox key={i} digit={digit} />
      ))}
    </Stack>
  );
};

const DigitBox = ({ digit }: { digit: string }) => {
  const transitions = useTransition(digit, {
    from: { transform: "translateY(-100%)", opacity: 0 },
    enter: { transform: "translateY(0%)", opacity: 1 },
    leave: { transform: "translateY(100%)", opacity: 0 },
    config: { tension: 150, friction: 10 },
  });

  return (
    <Box
      sx={{
        border: 1,
        borderColor: "primary.main",
        backgroundColor: "white",
        boxShadow: "inset 0 3px 6px rgba(0, 0, 0, 0.2)",
        borderRadius: 2,
        position: "relative",
        overflow: "hidden",
        width: {
          xs: 33,
          sm: 36,
          md: 43,
        },
        height: {
          xs: 41,
          sm: 45,
          md: 54,
        },
      }}
    >
      {transitions((style, d) => (
        <animated.div
          style={{
            ...style,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {d}
        </animated.div>
      ))}
    </Box>
  );
};

const TextDisplay = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <span style={{ textTransform: "capitalize" }}>{t("files")} </span>
      {t("already-converted")}
    </Box>
  );
};

export default UploadsCounter;
