import Container from "@cospex/client/converter/components/Container";
import SecurityImage0 from "@cospex/client/converter/img/security_image_0.svg";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Stack, Typography } from "@mui/material";
import { useParallax } from "react-scroll-parallax";

export default function SecuritySection() {
  const { t } = useTranslation();
  return (
    <Container
      backgroundColor="#1A0F4E"
      sx={{
        py: 4,
        mt: {
          md: 8,
          xs: 0,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        gap={4}
      >
        <Typography variant="h6" color="white">
          {t("homepage-security-title")}
        </Typography>
        <Box
          ref={
            useParallax({
              speed: -4,
            }).ref
          }
          component="img"
          src={SecurityImage0}
          alt="Security Image 0"
          sx={{
            maxWidth: 150,
            mt: {
              md: -12,
              xs: 0,
            },
            width: {
              md: "auto",
              xs: 150,
            },
          }}
        />
      </Stack>
    </Container>
  );
}
