import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box } from "@mui/material";

export default function Filigram() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        transform: "translate(-50%, -50%) rotate(-45deg)",
        transformOrigin: "center center",
        position: "absolute",
        top: "50%",
        left: "50%",
        fontWeight: "bold",
        fontSize: 44,
        zIndex: 10,
        mixBlendMode: "difference",
        color: "rgba(255, 255, 255, 0.30)",
        textTransform: "uppercase",
      }}
    >
      {t("preview")}
    </Box>
  );
}
