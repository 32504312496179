import { API_PATH_UPDATE_PASSWORD } from "@cospex/client/constants";
import TextInput from "@cospex/client/forms/TextInput";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Container, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios, { isAxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

type ResetPasswordData = {
  firstPass: string;
  secondPass: string;
};

const validationSchema = z.object({
  firstPass: z.string().min(4, "update-pass-min"),
  secondPass: z.string().min(4, "update-pass-min"),
});

const UpdatePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { handleSubmit, control, formState } = useForm<ResetPasswordData>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      firstPass: "",
      secondPass: "",
    },
  });
  const [error, setErrMsg] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const mutation = useMutation({
    mutationFn: (variables: { firstPass: string; secondPass: string }) => {
      return axios.post(
        API_PATH_UPDATE_PASSWORD,
        JSON.stringify({
          formData: {
            token: token,
            plainPassword: {
              first: variables.firstPass,
              second: variables.secondPass,
            },
          },
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: () => {
      navigate("/login", { replace: true });
    },
    onError(error) {
      if (isAxiosError(error)) {
        setErrMsg(error.response?.data.error);
      }
    },
  });

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <Container
      sx={{
        minHeight: "66vh",
        py: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit((data: ResetPasswordData) =>
          mutation.mutate(data)
        )}
        sx={{ width: "100%", maxWidth: 800 }}
      >
        <Typography variant="h2">{t("update-pass-title")}</Typography>
        <Typography variant="body1" mb={2}>
          {t("update-pass-description")}
        </Typography>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <TextInput
            ref={ref}
            control={control}
            name="firstPass"
            formState={formState}
            label="Password"
            fullWidth
          />
          <TextInput
            control={control}
            name="secondPass"
            formState={formState}
            label="Reset password"
            fullWidth
          />
          <LoadingButton
            variant="contained"
            loading={mutation.isLoading}
            sx={{
              color: "white",
            }}
            disableElevation
            type="submit"
          >
            <span>{t("update-pass-title")}</span>
          </LoadingButton>
          {error && <Alert severity="error">{error}</Alert>}
        </Stack>
      </Box>
    </Container>
  );
};

export default UpdatePassword;
