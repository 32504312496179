import Filigram from "@cospex/client/converter/components/Filigram";
import PDFPreview from "@cospex/client/converter/components/PDFPreview";
import UniversalConversionPreview from "@cospex/client/converter/components/UniversalConversionPreview";
import { getExtensionColor } from "@cospex/client/converter/helpers";
import useImageMeta from "@cospex/client/converter/hooks/useImageMeta";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Stack, SxProps, Typography } from "@mui/material";

const getStyling = (upload: Upload) => {
  const color = getExtensionColor(upload.convertTo || "");
  return {
    style: {
      aspectRatio: 1 / 1.414,
      backgroundColor: "white",
      height: "100%",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      borderRadius: 1,
      borderStyle: "dashed",
      borderWidth: 1,
      borderColor: color,
      p: 1,
    },
    color,
  };
};

const ImageToPDFPreview = ({ upload, sx }: { upload: Upload; sx: SxProps }) => {
  const imageMeta = useImageMeta(upload);
  if (imageMeta === undefined) return null;
  if (imageMeta === null) return <UniversalConversionPreview upload={upload} />;
  return (
    <Box sx={sx}>
      <Box
        component="img"
        src={imageMeta.url}
        alt={upload.file.name}
        sx={{ width: "100%", height: "100%", objectFit: "contain" }}
      />
    </Box>
  );
};

const SkeletonPreview = ({ sx }: { sx: SxProps }) => {
  const skeletonStyles = {
    width: "80%",
    height: 5,
    backgroundColor: "#e0e0e0",
    mb: 0.5,
  };

  return (
    <Box sx={sx}>
      {[
        "80%",
        "60%",
        "90%",
        "80%",
        "70%",
        "80%",
        "20%",
        "90%",
        "80%",
        "40%",
      ].map((width, index) => (
        <Box key={index} sx={{ ...skeletonStyles, width }} />
      ))}
    </Box>
  );
};

const PDFConversionPreview = ({ upload }: { upload: Upload }) => {
  const { t } = useTranslation();
  const { style, color } = getStyling(upload);

  const renderPreviewInner = () => {
    // If converting from Image to PDF
    if (upload.baseType === "image") {
      return <ImageToPDFPreview upload={upload} sx={style} />;
    }
    // If converting PDF -> Anything
    if (upload.type === "pdf") {
      return <PDFPreview file={upload.file} sx={style} />;
    }
    // If converting anything -> PDF
    return <SkeletonPreview sx={style} />;
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
        sx={{ mb: 2 }}
      >
        <Typography variant="h6" fontWeight={400}>
          {t("payment-document-ready")}
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="center">
        <Box
          sx={{
            height: 220,
            position: "relative",
            userSelect: "none",
            pointerEvents: "none",
          }}
        >
          <Filigram />
          {renderPreviewInner()}
          <Box
            sx={{
              zIndex: 20,
              position: "absolute",
              top: 0,
              right: 0,
              transform: "translate(15%, 50%)",
              py: 0.25,
              px: 1,
              fontSize: 14,
              borderRadius: 1,
              boxShadow:
                "0 0 5px 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.2)",
              textTransform: "uppercase",
              color: "white",
              backgroundColor: color,
            }}
          >
            {upload.convertTo}
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default PDFConversionPreview;
